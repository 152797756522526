<script setup lang="ts">
import type { SimpleTableColumn } from '@ui/components/SimpleTable/types'
import { simpleTableVariant } from '@ui/components/SimpleTable'
import { tv } from 'tailwind-variants'

interface Item {
  dataType: string
  displayName: string
  value: string
}

const props = withDefaults(defineProps<{ data: Item[] }>(), {
  data: () => [],
})

const data = computed(() => {
  const result: Record<string, any> = {}

  for (const item of props.data) {
    const key = camel(item.displayName)
    result[key] = item.value
  }

  return result
})
const columns = computed<SimpleTableColumn[]>(() =>
  props.data.map((item) => ({
    id: camel(item.displayName),
    header: item.displayName,
    cell: (value) => value ?? '-',
  })),
)

const variant = tv({
  extend: simpleTableVariant,
  slots: {
    tbodyTr: 'bg-transparent',
  },
})
</script>

<template>
  <div>
    <SimpleTable
      :columns="columns"
      :data="data"
      orientation="vertical"
      :divided-rows="false"
      :variant="variant"
      padding="sm"
      font-size="sm"
    />
  </div>
</template>
